import { DropdownOption } from './dropdowns/dropdownOptions';
import { format } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enumToDropdownOptions(enumme: any): DropdownOption[] {
  const keys = Object.keys(enumme);
  const array = keys
    .filter((key, index) => index >= keys.length / 2 && enumme[key] >= 0)
    .map((key) => new DropdownOption(enumme[key], key));
  return array;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enumStringValueToDropdownOptions(
  enumme: any
): DropdownOption[] {
  const keys = Object.keys(enumme);
  const array = keys.map((key) => new DropdownOption(enumme[key], key));
  return array;
}

export function formatCurrency(num: number) {
  const result = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);

  // remove decimal part if it equals 0
  if (parseInt(result.split('.')[1]) === 0) {
    return result.split('.')[0];
  }
  return result;
}

export function formatThousand(num: number) {
  return new Intl.NumberFormat('en-US').format(num);
}

export function formatDate(
  timestamp: number,
  pattern: string,
  addDate?: number | undefined
) {
  if (!addDate) {
    return format(timestamp, pattern);
  }

  const dateConverted = new Date(timestamp);
  dateConverted.setDate(dateConverted.getDate() + addDate);
  return format(dateConverted, pattern);
}

export const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (_, index) => args[index] || '');

export function convertCurrencyToNumber(num: any): number {
  if (num) {
    const str = num.toString().replaceAll(',', '');
    return Number.parseFloat(str);
  }
  return 0;
}

export const stringIsNumber = (value) => isNaN(Number(value)) === false;

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
